import React, { useState, useEffect, useCallback } from "react";
import logo1 from "../Assets/Images/20001.png";

import { useGlobalContext } from "../context/walletContext";
import NFTFiles from "../Assets/data";
import NftCard from "./NftCard";
import { usePrice } from "../hooks/usePrice";

const Nft = () => {
  const {
    checkAllowance,
    approveToken,
    address,
    mintNFT,
    tokenValue,
    network,
  } = useGlobalContext();
    
  const [tokenApprovalFlag, setTokenApprovalFlag] = useState([false]);

  const handleNFTBuy = useCallback(async (token, nftNo) => {
    const index = nftNo - 1;
    const updatedFlags = [...tokenApprovalFlag];
    
    if (token === "VTC" && !updatedFlags[0]) {
      const isApproved = await approveToken(NFTFiles.VTC);
      if (isApproved) {
        updatedFlags[0] = true;
        setTokenApprovalFlag(updatedFlags);
      }
      return;
    }
    await mintNFT(NFTFiles.VTC, nftNo);
  }, [tokenApprovalFlag, approveToken, mintNFT]);

  const checkAndUpdateApprovals = useCallback(async () => {
    const approvals = await Promise.all([
      checkAllowance(NFTFiles.VTC)
    ]);
    setTokenApprovalFlag(approvals);
  }, [checkAllowance]);

  useEffect(() => {
    if (address && network === NFTFiles.NETWORK_ID) {
      checkAndUpdateApprovals();
    }
  }, [address, network, checkAndUpdateApprovals]);

  const  {price} = usePrice()

  return (
    <>

      <section className="nft mb-5">
        <div className="container pt-4">
          <h2 className="nft-headline mt-5">
            VitnixX <span>NFT</span>{" "}
          </h2>
          <div className=" mx-auto d-flex justify-content-center  w-fit mt-4 mb-5 ">
            <button
              type="button"
              className="md:d-flex pricebtn items-center rounded bg-transparent border    py-2  px-3 mx-2 rounded-2xl"
            >
              <img
                className="w-6 mr-2"
                src={logo1}
                width={24}
                height={24}
                alt="logo-gif"
              />
              <span className=" text-white">VTC Price : ${price}</span>
            </button>
          </div>


          <div className="row">
            {NFTFiles.nft.map((nft, index) => (
              <NftCard 
                key={nft.id}
                nftData={nft}
                index={index}
                tokenValue={price}
                tokenApprovalFlags={tokenApprovalFlag}
                onBuy={handleNFTBuy}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

const PriceDisplay = ({ tokenValue }) => (
  <div className="mx-auto d-flex justify-content-center w-fit mt-4 mb-5">
    {[["VTC", 0], ["CUSD", 1]].map(([label, idx]) => (
      <button key={label} type="button" className="pricebtn md:d-flex items-center rounded bg-transparent border py-2 px-3 mx-2 rounded-2xl">
        <span className="text-white">{label} Price: ${tokenValue[idx]}</span>
      </button>
    ))}
  </div>
);

export default Nft;
