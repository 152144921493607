import React, { useContext, useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import NFTABI from '../Assets/abi/VitnixxNFTOracle.json'
import ERC20ABI from '../Assets/abi/ERC20.json'
import { ethers, providers } from "ethers";
import NFTFiles from "../Assets/data";

const walletContext = React.createContext();

export default function WalletContextProvider({ children }) {
  const [address, setAddress] = useState(null);
  const [network, setNetwork] = useState(null);

  const [tokenValue, setTokenValue] = useState([null, null]);

  const getContract = () => {
    if (!address) {
      toast.error("Please connect your wallet first");
      return;
    }
    const provider = new providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      NFTFiles.ORACLE_ADDRESS,
      NFTABI,
      signer
    );
    return contract;
  };

  const getERC20Contract = (token) => {
    if (!address) {
      toast.error("Please connect your wallet first");
      return;
    }
    const provider = new providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(token, ERC20ABI, signer);
    return contract;
  };

  const connectWallet = async () => {
    if (!window.ethereum) {
      toast.error("No Wallet Detected.");
      return;
    }

    try {
      const provider = new providers.Web3Provider(window.ethereum);
      const add = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      setAddress(add[0]);
      const network = await provider.getNetwork();
      setNetwork(network.chainId);
      toast.success("Wallet Connected");
    } catch (e) {
      toast.error("Wallet Connection Failed");
    }
  };

  const approveToken = async (token) => {
    if (!address) {
      toast.error("Please connect your wallet first");
      return;
    }

    if (!checkNetwork()) {
      return;
    }

    const contract = getERC20Contract(token);
    let amount = ethers.constants.MaxUint256;
    const toastId = toast.loading("Loading...");

    try {
      const tx = await contract.approve(NFTFiles.ORACLE_ADDRESS, amount);
      const receipt = await tx.wait();
      if (receipt.status === 1) {
        toast.success("Token Approved");
        toast.dismiss(toastId);
        return true; // Transaction was successful
      } else {
        toast.error("Approve Failed");
        toast.dismiss(toastId);
        return false; // Transaction failed
      }
    } catch (error) {
      toast.error("Approve Failed");
      toast.dismiss(toastId);
      return false; // Transaction was rejected or an error occurred
    }
  };

  const checkAllowance = async (token) => {

    if (!address) {
      toast.error("Please connect your wallet first");
      return;
    }
    try {
      const contract = getERC20Contract(token);
      let allowance = await contract.allowance(
        address,
        NFTFiles.ORACLE_ADDRESS
      );
      let finalAllowance = ethers.utils.formatEther(allowance);
      finalAllowance = parseFloat(finalAllowance);
      if (finalAllowance < 10000000) return false;
      else return true;
    } catch (e) {
      console.log("check allowance error : ", e);
    }
  };

  const getReferrers = async (address) => {
    const graphqlUrl = "https://api.studio.thegraph.com/query/76981/vtc-nft-marketplace/version/latest"
    const query = `
    query {
      referrals(where: {referral: "${address}"}) {
        user
        amount
        level
        transactionHash
      }
    }
    `

    const response = await fetch(graphqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ query }),
    });

    const data = await response.json();
    console.debug(data);

    return data.data.referrals;
  }

  const checkBalance = async (token, amt) => {
    try {

      const contract = getERC20Contract(token);
      let balance = await contract.balanceOf(address);

      let finalBalance = ethers.utils.formatEther(balance);
      finalBalance = parseFloat(finalBalance);

      if (finalBalance < amt) return false;
      else return true;

    } catch (e) {
      console.log("check balance error : ", e);
    }
  };

  const mintNFT = async (token, nftNo) => {

    if (!address) {
      toast.error("Please connect your wallet first");
      return;
    }

    if (!checkNetwork()) {
      return;
    }

    nftNo = parseInt(nftNo) - 1;
    let val = NFTFiles.nft[nftNo].value;
    const contract = getContract();

    const urlParams = new URLSearchParams(window.location.search);
    const referrerAddress = urlParams.get('referral') || '0x0000000000000000000000000000000000000000';

    console.debug(referrerAddress);
    // let parseTokenDollar = ethers.utils.formatUnits(tokenDollar, 18);

    // if (hasMoreThanThreeDecimals(parseTokenDollar)) amount = (val / parseTokenDollar) + 0.0001;
    // else amount = val / parseTokenDollar;


    // let finalAmount = ethers.utils.parseEther(amount);
    const toastId = toast.loading("Loading...");
    try {

      const res = await contract.buyNFT(
        val,
        referrerAddress
      );
      const receipt = await res.wait();

      if (receipt.status === 1) {
        toast.success("NFT Minted");
        toast.dismiss(toastId);
        return true; // Transaction was successful
      } else {
        toast.error("Mint Failed");
        toast.dismiss(toastId);
        return false; // Transaction failed
      }
    } catch (e) {
      toast.error(e.reason ? e.reason : e.data ? e.data.message : e.message);
      toast.dismiss(toastId);
      console.error("mint nft error : ", e);
    }

  };


  const checkNetwork = () => {
    if (network !== NFTFiles.NETWORK_ID) {
      toast.error("Wrong Network");
      return false;
    } else return true;
  };

  const requestNetworkShift = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: NFTFiles.NETWORK_HEXA_ID }],
      });
      setNetwork(NFTFiles.NETWORK_ID);
      toast.success("Network Switched");
    } catch (switchError) {
      if (switchError.code === 4902) {
        toast.error(
          "Please add the Matic Mumbai Testnet to your wallet and try again."
        );
      } else {
        toast.error("Failed to switch network.");
      }
      return;
    }
  };

  const getTokensPrice = async () => {

    try {

      const provider = new providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(
        NFTFiles.VITINIXX_ORACLE,
        NFTABI,
        provider
      );

      const tokenPrices = await contract.getPrice();

      const _vtcPrice = tokenPrices;

      let vtcPrice = ethers.utils.formatUnits(_vtcPrice, 18);


      if (hasMoreThanThreeDecimals(vtcPrice)) vtcPrice = parseInt(vtcPrice).toFixed(3);

      setTokenValue([vtcPrice]);
    } catch (e) {
      console.error("err while fetching tokenPrice : ", e);
    }

  };

  function hasMoreThanThreeDecimals(num) {
    const str = num.toString();
    const decimalIndex = str.indexOf(".");
    if (decimalIndex === -1) {
      return false;
    }
    const decimalPart = str.slice(decimalIndex + 1);
    return decimalPart.length > 3;
  }


  async function addTokenToMetaMask(token, symbol) {

    if (!checkNetwork()) {
      return;
    }
    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: token, // The address that the token is at.
            symbol: symbol,
            decimals: 18,
            image: "",
          },
        },
      });

      if (wasAdded) {
        toast.success("Token Added");
      } else {
        toast.error("Failed to add token");
      }
    } catch (error) {
      console.log(error);
    }
  }

  // Pair handler...

  const approvePair = async (token1, token2) => {
    await approveToken(token1);
    await approveToken(token2);
  }

  const mintWithPair = async (pairNo, NFTno) => {

    if (!address) {
      toast.error("Please connect your wallet first");
      return;
    }

    if (!checkNetwork()) {
      return;
    }

    const toastId = toast.loading("Loading...");
    try {

      NFTno = parseInt(NFTno) - 1;
      let val = NFTFiles.nft[NFTno].value;

      const contract = getContract();

      let amount = ethers.utils.parseEther(val.toString());

      const res = await contract.callSafeMint(
        NFTFiles.VTC,
        amount,
        address,
        pairNo,
        NFTno + 1
      );
      const receipt = await res.wait();

      if (receipt.status === 1) {
        toast.success("NFT Minted");
        toast.dismiss(toastId);
        return true; // Transaction was successful
      } else {
        toast.error("Mint Failed");
        toast.dismiss(toastId);
        return false; // Transaction failed
      }
    } catch (e) {
      let errorMessage = e.message.split("(")[0].trim();
      errorMessage = e.message.split("[")[0].trim();
      toast.error(errorMessage);
      toast.dismiss(toastId);
      console.error("mint nft error : ", e);
    }


  }


  useEffect(() => {
    if (address && network == NFTFiles.NETWORK_ID) {
      getTokensPrice();
    }
  }, [address, network]);


  useEffect(() => {
    connectWallet();
  }, []);

  return (
    <walletContext.Provider
      value={{
        connectWallet,
        address,
        setAddress,
        checkAllowance,
        approveToken,
        mintNFT,
        checkNetwork,
        network,
        requestNetworkShift,
        tokenValue,
        addTokenToMetaMask,
        approvePair,
        mintWithPair,
        getReferrers
      }}
    >
      <Toaster />
      {children}
    </walletContext.Provider>
  );
}

export function useGlobalContext() {
  return useContext(walletContext);
}
