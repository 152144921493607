import React from "react";

const ReferralBonusCard = ({ img, value, title, className = "", handleCopyToClipboard=null }) => {
  return (
    <div className={` text-center px-4 py-2  countinbox ${className}`} onClick={handleCopyToClipboard}>
      <img src={img} alt={title} className="mb-2 image-ref " width="50" height="50" />
      <p className="ref-p">{title}</p>
      <h3 className="ref-font">{value}</h3>
    </div>
  );
};

export default ReferralBonusCard;
