import React, { useEffect, useRef } from 'react';
import { Carousel } from 'bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; 

import Nft from './Nft';

import Referralpage from '../Pages/Referral/Referralpage';

function MergedCarousel() {
    const carouselRef = useRef(null);

    useEffect(() => {
        const carouselInstance = new Carousel(carouselRef.current);
        return () => carouselInstance.dispose();
    }, []);

    return (
        <div ref={carouselRef} id="carouselExampleIndicators" className="carousel slide nfthis" data-bs-interval="false">
         
            <div className="carousel-indicators mb-4">
                <button  type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active crl-btn " aria-current="true" aria-label="Slide 1">
                    <span>Nfts</span>
                </button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2" className='crl-btn'>
                    <span>Referral</span>
                </button>
            </div>

        
            <div className="carousel-inner">
        
                <div className="carousel-item active">
                    <Nft />
                </div>

             
                <div className="carousel-item">
                    <Referralpage />
                </div>
            </div>

     
            {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button> */}
        </div>
    );
}

export default MergedCarousel;
