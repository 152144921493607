import React from "react";

const Referraltable = ({ referrers }) => {
  const referralInfos = referrers.map((referrer) => ({
    walletAddress: referrer.user,
    bonusAmount: (referrer.amount / 1e18).toFixed(2),
    transactionHash: referrer.transactionHash,
    level: referrer.level
  }));

  return (
    <section className="mb-5">
      <div className="container">
 
      <div className="text-center mb-4">
        
        <h2 className="nft-headline">
          Referral  <span>Details</span>{" "}
        </h2>
      </div>


        <div className="table-responsive mx-auto" style={{ maxWidth: "900px" }}>
          <table className="table  text-center">
            <thead className="">
              <tr>
                <th scope="col">Sr. No</th>
                <th scope="col">Wallet Address</th>
                <th scope="col">Bonus Amount</th>
                <th scope="col">Transaction Hash</th>
                <th scope="col">Level</th>
              </tr>
            </thead>
            <tbody>
              {
                referralInfos.length === 0 ? (
                  <tr>
                    <td colSpan="5">No data found</td>
                  </tr>
                ) : (
                  referralInfos.map((item, index) => (
                    <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.walletAddress}</td>
                  <td>{item.bonusAmount}</td>
                  <td>{item.transactionHash}</td>
                  <td>{item.level}</td>
                </tr>
              )
              )
              )}
            </tbody>
          </table>
        </div>


        {/* <div className="text-center mt-5">
          <button className="btn btn-primary">Claim</button>
          <p className="mt-3">You can claim after 27 April 09:00 PM (GMT)</p>
          <p className="mt-2">
            You will be claiming 20% of your referral earnings in an interval of
            30 days, total 5 claims.
          </p>
        </div> */}
      </div>
    </section>
  );
};

export default Referraltable;
