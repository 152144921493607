import React from "react";
import VTClogo from "../../Assets/Images/vtclogo.png";
import giftIcon from "../../Assets/Images/features1.webp";
import referralIcon from "../../Assets/Images/features2.webp";
import ReferralBonusCard from "./Referralbonuscard";
import toast from "react-hot-toast";

const ReferralBonus = ({ referrers, address }) => {

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(`https://nft.vitnixx.com/?referral=${address}`)
      .then(() => {
        toast.success("Referral link copied!");
      })
      .catch((err) => {
        toast.error("Failed to copy wallet address.");
        console.error("Copy failed:", err);
      });
  };
  return (
    <section className="">
      <div className="container">
    
        <div className="text-center mb-4">
        
          <h2 className="nft-headline">
            Referral  <span>Details</span>{" "}
          </h2>
        </div>

       
        <div className="row justify-content-center g-3">
        
          <div className="col-12 col-sm-6 col-md-4">
            <ReferralBonusCard
              img={VTClogo}
              value={referrers.reduce((acc, curr) => acc + parseFloat((curr.amount / 1e18).toFixed(2)), 0)}
              title="Your Referral Bonus"
            />
          </div>

    
          <div className="col-12 col-sm-6 col-md-4">
            <ReferralBonusCard
              img={giftIcon}
              value="5% - 4% - 2%"
              title="Active Referral Programs"
            />
          </div>

     
          <div className="col-12 col-sm-6 col-md-4">
            <ReferralBonusCard
              img={referralIcon}
              value={`Click to Copy Referral Link`}
              title="Referral Link"
              handleCopyToClipboard={handleCopyToClipboard}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReferralBonus;
