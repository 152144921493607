import React, { useEffect, useState } from 'react'
import ReferralBonus from './Referralbonus'
import Referraltable from './Referraltable'
import './Referral.css'
import { useGlobalContext } from '../../context/walletContext'

const Referralpage = () => {
  const { getReferrers, address } = useGlobalContext();
  const [referrers, setReferrers] = useState([]);

  useEffect(() => {
    const fetchReferrers = async () => {
      const referrers = await getReferrers(address);
      setReferrers(referrers);
    };
    fetchReferrers();
  }, [address]);

  console.debug(referrers, address);
  return (
   <>
<ReferralBonus referrers={referrers} address={address} />
<Referraltable referrers={referrers} />


   </>
  )
}

export default Referralpage