const NFTFiles = {
  nft: [
    {
      name: "GALACTIC PIONEER",
      value: 100,
      link: "https://nftstorage.link/ipfs/bafkreigzswr7czgmo4y5bh7aoa26iyxjzvdhnkkar5a4lrmqpwjv3aqvxm",
      video: "./Images/001.mp4",
    },
    {
      name: "CELESTIAL VOYAGER",
      value: 500,
      link: "https://nftstorage.link/ipfs/bafkreicv77soh56wnqll7c2p42mojldmldbwltgbhzkdxmbk36zgjd727e",
      video: "./Images/002.mp4",
    },

    {
      name: "STELLAR COMMANDER",
      value: 1000,
      link: "https://nftstorage.link/ipfs/bafkreihbr5eiap5skm7jbjtxanrpgbcgdsx3mkwzu4nr4cwnwovlauuziu",
      video: "./Images/003.mp4",

    },

    {
      name: "COSMIC EXPLORER",
      value: 2500,
      link: "https://nftstorage.link/ipfs/bafkreid3eloymicirywucfm7uacxnrbseneqesug4wzexea4slz5kii2ya",
      video: "./Images/004.mp4",

    },

    {
      name: "ASTRO TRAILBLAZER",
      value: 5000,
      link: "https://nftstorage.link/ipfs/bafkreicqhpmfst7wtcgqo26xkewi5voqld5qhyac4lz6wgtlcs64cgoo3m",
      video: "./Images/005.mp4",


    },
    {
      name: "NEBULA NAVIGATOR",
      value: 10000,
      link: "https://nftstorage.link/ipfs/bafkreih3kaem7c4woxssalfbdsi3l4h4jr57xyj4b74rijx6iuvvksheam",
      video: "./Images/006.mp4",

    },

    {
      name: "SPACE ODYSSEY CAPTAIN",
      value: 25000,
      link: "https://nftstorage.link/ipfs/bafkreia4wjsepiozf7qbu4c5nudfmtkle6bnexrkeqo6dtivnukaf6slxu",
      video: "./Images/007.mp4",
    }
  ],

  NFT_CONTRACTS: [
    "0x0d2e4e73139aebdd6f3ebad0c87aecaa7c4acc0c",
    "0xdb4475659338A8c92b61f2794A86fBae0F6d98f5",
    "0x0806E1e016dac2587ad14EEf1779119Bc507711C",
    "0x902Fd2f5d79F10e1FfA5A61FaD7A9a518BFe1851",
    "0x9c14253d987821D2cB9De673b371e4db5154EFC5",
    "0x58B163E254BfD5a579b222BAA70B0560ef596502",
    "0xAb5C26753D623793dfB14DF7C5dBB51CCb92A469",
  ],

  VTC: "0x281613fbFddcD9c6a9c40507513793d908f6892b",
  ORACLE_ADDRESS: "0xbBa27fD24bF0D3554C5A334e46a799C493ec9508",

  NETWORK_ID: 137,
  NETWORK_HEXA_ID: "0x89",

};

export default NFTFiles;
