import React, { memo, useState } from 'react';
import ReactPlayer from 'react-player';
import Copy from 'copy-to-clipboard';
import { toast } from 'react-hot-toast';
import NFTFiles from '../Assets/data';
import copy from "../Assets/Images/icons8-copy-64.png";

const NftCard = memo(({ nftData, index, tokenValue, tokenApprovalFlags, onBuy }) => {
  const handleAddressShow = (address) => 
    address.slice(0, 4) + "..." + address.slice(-4);

  const handleCopyAddress = (address) => {
    Copy(address);
    toast.success("Address Copied");
  };

  const purchaseOptions = [
    { type: "VTC", label: "VTC", flagIndex: 0 }
  ];

  const [isInTransaction, setIsInTransaction] = useState(false);
  const handleBuy = async (token, nftNo) => {
    setIsInTransaction(true);
    try {
      await onBuy(token, nftNo);
      setIsInTransaction(false);
    } catch (error) {
      console.error("Error buying NFT:", error);
      toast.error("Error buying NFT");
      setIsInTransaction(false);
    }
    finally {
      setIsInTransaction(false);
    }
  }

  return (
    <div className="col-12 col-md-6 col-lg-4 col-xl-3">
      <div className="padding-box">
        <div className="element-container">
          <div className="inner-box">
            {[...Array(4)].map((_, i) => (
              <div key={i} className={`boder bd${i+1}`}>
                <i className="icon icon-stroke"></i>
              </div>
            ))}
            <div className="wrap-content">
              <div className='d-flex justify-content-center  '>
              <ReactPlayer
                className="react-player"
                url={NFTFiles.nft[index].video}
                width="240px"
                height="auto"
                muted
                loop
                playing
                playsinline
              />
              </div>
            
              <div className="pxl-space d-flex flex-column gap-y-1 pt-4 pb-4">
                <AddressRow 
                  address={NFTFiles.NFT_CONTRACTS[index]}
                  onCopy={handleCopyAddress}
                  handleAddressShow={handleAddressShow}
                />
                <ValueRow label="VTC Value" value={`${nftData.value / tokenValue} VTC`} isStatic />
                <ValueRow label="NFT Value" value={`$${nftData.value}`} isStatic />
                

                {purchaseOptions.map((opt, idx) => (
                  <PurchaseButton
                    key={idx}
                    option={opt}
                    nftNo={index + 1}
                    tokenApprovalFlags={tokenApprovalFlags}
                    onBuy={handleBuy}
                    isInTransaction={isInTransaction}
                  />

                ))}
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const AddressRow = ({ address, onCopy, handleAddressShow }) => (
  <div className="d-flex justify-content-between align-items-center text-lg">
    <span>Address</span>
    <span className="d-flex align-items-center gap-x-2">
      {handleAddressShow(address)}
      <span onClick={() => onCopy(address)}>
        <img src={copy} className="copyimg" alt="Copy" />
      </span>
    </span>
  </div>

);

const ValueRow = ({ label, value, isStatic }) => (
  <div className="d-flex justify-content-between align-items-center text-lg">
    <span>{label}</span>
    <span>{isStatic ? value : typeof value === 'number' ? value.toFixed(2) : '--'}</span>
  </div>
);

const PurchaseButton = ({ option, nftNo, tokenApprovalFlags, onBuy, isInTransaction }) => {
  const isApproved = option.flags 
    ? option.flags.every(i => tokenApprovalFlags[i])
    : tokenApprovalFlags[option.flagIndex];


  return (
    <button
      type="button"
      className="btn btn-primary w-full mt-3 mb-auto"
      onClick={() => onBuy(option.type, nftNo)}
      disabled={isInTransaction}
    >
      {
        isApproved && !isInTransaction && `Buy with ${option.label}`
      }
      {
        !isApproved && !isInTransaction && `Approve ${option.label}`
      }
      {isInTransaction && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
    </button>
  );
};

export default NftCard; 