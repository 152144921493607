import React from "react";
import bannerimage2 from "../Assets/Images/v3.gif";
import NFTFiles from "../Assets/data";
import { useGlobalContext } from "../context/walletContext";
import toast from "react-hot-toast";
import copyicon from "../Assets/Images/icons8-copy-24.png";

const Banner = () => {
  const { addTokenToMetaMask, address } = useGlobalContext();

  const fullWalletAddress = `https://nft.vitnixx.com/?referral=${address}`;

  const displayedAddress = `${fullWalletAddress.slice(
    0,
    20
  )}...${fullWalletAddress.slice(-4)}`;

  const handleAddToken = async (tokenStr) => {
    if (tokenStr === "VTC") {
      await addTokenToMetaMask(NFTFiles.VTC, "VTC");
    } else {
      await addTokenToMetaMask(NFTFiles.CZX, "CZX");
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(fullWalletAddress)
      .then(() => {
        toast.success("Referral link copied!");
      })
      .catch((err) => {
        toast.error("Failed to copy wallet address.");
        console.error("Copy failed:", err);
      });
  };

  return (
    <>
      <section className="banner-area" id="Home">
        <div className="container">
          <div className="row align-items-center gap-3 gap-lg-0">
            <div className="col-12 col-lg-6">
              <div className="info-group" data-aos="fade-right">
                <h1
                  className="pxl-item--title highlight-default"
                  data-wow-delay="ms"
                >
                  VitnixX <span> NFT </span>Marketplace
                </h1>
                <p>
                  Welcome to the VitnixX NFT Marketplace, a vibrant and
                  decentralized hub for digital collectors and enthusiasts. Our
                  NFT marketplace revolutionizes the way you discover and trade
                  unique digital collectibles.
                </p>

                <button
                  className="copyRefferalCode mb-3"
                  onClick={handleCopyToClipboard}
                >
                  Referral Link: <span>{displayedAddress}</span>
                  <img className="copy-icon" src={copyicon} alt="Copy Icon" />
                </button>

                <div className="d-flex flex-sm-row flex-column align-items-sm-start align-items-center gap-2 gap-sm-3">
                  <div className="banner-btn-grp mt-0">
                    <a href="#" className="btn-main">
                      <span onClick={() => handleAddToken("VTC")}>Add VTC</span>
                    </a>
                  </div>
                  <div className="banner-btn-grp mt-sm-0 mt-1">
                    <a href="https://swap.vitnixx.com/" className="btn-main">
                      <span>Buy VTC</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="banner-img-group">
                <img
                  className="color-changing-element"
                  src={bannerimage2}
                  height={640}
                  width={640}
                  alt="banner-logo"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
